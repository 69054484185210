<template>

    <a-row style="margin: 13px;;">

        <a-card  :bordered="false" class="card-profile-head dark" :bodyStyle="{padding: 0,}" title="Yetushare Docs v 0.1.1.0">

            

            <a-card-header style="padding: 13px;">
                <a-icon type="file-markdown" style="font-size: 24px; margin-right: 13px;"></a-icon>

                <span><b>Note:</b> API under active development.</span>
                
            </a-card-header>

            <a-divider></a-divider>

        <div style="padding: 37px; margin: 13px; margin-top: 57px;" v-html="docText"></div>

</a-card>

    </a-row>
	
		
</template>

<script>

import {getDocText} from '../services/v1/services';

import {simpleMarkdown} from '../services/v1/data_methods';

    export default ({
        async mounted() {
            console.log('Docs page mounted.');

            const res = await getDocText();

            this.docText = simpleMarkdown(res);

        },
        data() {
            return {
                docText: "",
            }
        },
        methods: {
            markdownToHtml(description) {
                return marked(description);
            },
        }
    })

</script>